import { css, keyframes } from "@emotion/react";
import { graphql } from "gatsby";
import { CallToAction, Card, Quotes, Section } from "../components";
import Theme from "../components/Theme";
import { getAssetImage, useTextRotator } from "../core";
import { PageLayout } from "../layouts/Page";
import { CallToActionData } from "../queries/DataTypes";

const blinkFrames = keyframes`
0% {
  opacity: 0;
}
50% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;

const styles = {
  manifesto: css`
    background-image: url(/assets/back-manifesto.png);
    background-position: 50% 0;
    background-repeat: repeat-x;
    height: 530px;

    display: flex;
    align-items: center;
    > div {
      align-items: center;
      text-align: center;
    }
  `,
  map: (image: string) => css`
    background-image: url(${image});
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 480px;

    display: flex;
    align-items: center;

    ${Theme.mq.xs} {
      align-items: flex-start;
      padding-top: 48px;
    }
  `,
  card: css`
    padding: 30px;
    width: 300px;
    flex-direction: column;
    > strong {
      color: ${Theme.palette.text};
      margin-bottom: 12px;
    }
  `,
  quotes: css`
    align-items: center;
    > div {
      ${Theme.mq.md_} {
        width: 60%;
      }

      ${Theme.mq._sm} {
        width: 100%;
      }
    }
  `,
  cursor: css`
    animation: ${blinkFrames} 0.5s ease-in-out 0s infinite;
  `,
};

const Manifesto = ({ items }: { items: Array<string> }) => {
  const [text, active, desired] = useTextRotator(items, 5000);
  return (
    <div css={styles.manifesto}>
      <Section>
        <h2 css={Theme.typography.h2}>
          {text}
          {active !== desired && <span css={styles.cursor}>|</span>}
        </h2>
      </Section>
    </div>
  );
};

interface PageProps {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        keywords: string;
        hero: CallToActionData;
        manifesto: {
          enabled: boolean;
          items?: Array<string>;
        };
        map: {
          title: string;
          address: string;
          image: string;
        };
        quoteRefs?: Array<string>;
      };
    };
  };
}

export default ({
  data: {
    mdx: {
      frontmatter: { title, description, keywords, hero, manifesto, map, quoteRefs },
    },
  },
}: PageProps) => (
  <PageLayout title={title} description={description} keywords={keywords}>
    <header>
      <Section marginTop marginBottom>
        <CallToAction
          width="50%"
          appearance="secondary"
          heading={hero.heading}
          content={hero.content}
          alignment="center"
        />
      </Section>
    </header>
    <main>
      {manifesto && manifesto.enabled && manifesto.items && manifesto.items.length > 0 && (
        <Manifesto items={manifesto.items} />
      )}
      <div css={styles.map(getAssetImage(map.image))} title={map.address}>
        <Section>
          <Card sx={styles.card}>
            <strong css={Theme.typography.body.bold}>{map.title}</strong>
            <p css={Theme.typography.body.regular}>{map.address}</p>
          </Card>
        </Section>
      </div>
      {quoteRefs && quoteRefs.length > 0 && (
        <Section marginTop marginBottom sx={styles.quotes}>
          <Quotes keys={quoteRefs} />
        </Section>
      )}
    </main>
  </PageLayout>
);

export const query = graphql`
  query AboutQuery {
    mdx(fileAbsolutePath: { glob: "**/pages/about.md" }) {
      frontmatter {
        title
        description
        keywords
        hero {
          heading
          content
        }
        manifesto {
          enabled
          items
        }
        map {
          title
          address
          image
        }
        quoteRefs
      }
    }
  }
`;
